import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faExternalLinkAlt,
  faUserSecret,
  faTrophy,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faPlusCircle,
  faThList,
  faUserCog,
  faStickyNote,
  faStepForward,
  faGlasses,
  faInfoCircle,
  faCheck,
  faChartBar,
  faDiceTwo,
  faPeopleArrows,
  faComment,
  faShare,
  faFlag
} from "@fortawesome/free-solid-svg-icons";

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faExternalLinkAlt, faUserSecret, 
  faTrophy, faAngleDoubleRight, faPlusCircle, faThList, faUserCog, faStickyNote, faStepForward, faGlasses,
  faInfoCircle, faCheck, faChartBar, faAngleDoubleLeft, faDiceTwo, faPeopleArrows, faComment, faShare, faFlag);

export { FontAwesomeIcon };